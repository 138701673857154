import {Form} from 'react-bootstrap';
import { useEffect,useState } from 'react';
import {FaCode, FaDiscord, FaWindowRestore} from 'react-icons/fa'
import axios from 'axios'

const Data = (props) =>{

    const [hasPhantom,setHasPhantom] = useState(false);
    const [phantomConnected,setPhantomConnected] = useState()
    const [solAddress,setSolAddress] = useState()
    const [code,setCode] = useState()
    const [discordUser,setDiscordUser] = useState()
    
    useEffect(()=>{
        console.log(props.nfts)
        if(window.solana){
            if(!hasPhantom){
            setHasPhantom(true);
            }
        }
        window.addEventListener('message',(event)=>{
            var data = event.data
            try{
                if(data.includes('code')){
                    const code = data.split(' ')[1]
                    console.log(code)
                    getUser(code)
                    window.removeEventListener('message')
                }
            }
            catch(error){
                console.log("Not code")
            }
        })
        return()=>{
            window.removeEventListener('message')
        }
    },[]);

    async function phantomLogin(){
        try{
        console.log("Phantom login")
        const sol_address = await window.solana.connect();
        console.log(sol_address.publicKey.toString()+" "+window.solana.isConnected);
        setSolAddress(sol_address.publicKey.toString());
        setPhantomConnected(true);
        }
        catch(error){
            console.log(error)
        }
    }

    async function discordLogin(){
        var wow='https://discord.com/api/oauth2/authorize?client_id=988459991360999424&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fredirect&response_type=code&scope=identify';
        const value=window.open(wow,"","height=200","width=200")
        console.log(value)
    }

    async function getUser(code){
        var url = 'http://127.0.0.1:8000/discord/getUser?code='
        const response = await axios.get(url+code)
        console.log(response.data)
        setDiscordUser(response.data['username'])
    }

    return(
        <div className='centerInScreen text'>
            <p>Tezos Address: {props.address}</p>
            {!phantomConnected?
            <button className='button' onClick={phantomLogin}>Connect Phantom</button>:
            <>
            <p className='text'>Solana Address: {solAddress}</p>
            </>
            }
        <Form>
            <Form.Group className='mb-3' style={{marginTop:'10px'}}>
                <Form.Label style={{paddingRight:"40px"}}>NFT ID:</Form.Label>
                <Form.Select className='text'>
                    {props.nfts.map((item)=>{
                        return(<option>{item}</option>)
                    })}
                </Form.Select>
            </Form.Group>
            
        </Form>
        <button className='button' onClick={discordLogin}><FaDiscord/></button>
        <p className='text'>Discord User: {discordUser}</p>
        </div>
    );
}

export default Data;