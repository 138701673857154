import { TezosToolkit } from '@taquito/taquito';
import { tzip16, Tzip16Module } from '@taquito/tzip16';
import {BeaconWallet} from '@taquito/beacon-wallet';
import {TempleWallet} from '@temple-wallet/dapp'
import { useEffect, useState } from 'react';
import Data from './Data';
const Component = () => {

    const [connected,setConnected] = useState(false);
    const [ownsNFT, setOwnsNFT] = useState(false);
    const [nfts,setNFTs] = useState(null)
    const [loading,setLoading] = useState(true)
    const [address, setAddress] =useState()

    useEffect(()=>{
        connectionStatus();
        templeavailability();
    },[connected]);

    async function templeavailability(){
        try{
            const available = await TempleWallet.isAvailable();
            if(!available){
                console.log("temple not available");
            }
            else{
                console.log("temple avaiable");
            }
        }
        catch(error){
            console.log('temple wow')
        }
    }

    const Tezos = new TezosToolkit('https://mainnet.api.tez.ie');
    // const wallet = new TempleWallet('MyApp');
    Tezos.addExtension(new Tzip16Module())

    const options = {
        name: "MyApp",
    };

    async function connectionStatus(){
        if(wallet){
            if(await wallet.client.getActiveAccount()){
                console.log("Connected");
                setLoading(true);
                setConnected(true);
                balanceof();
            }
            else{
                console.log("Not Connected");
                setConnected(false);
                setLoading(false)
            }
        }
        else{
            setConnected(false);
        }
    }

    const wallet = new BeaconWallet(options);
    
    async function metadata(){
        console.log(wallet.client.getActiveAccount())
        Tezos.wallet.at('KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5',tzip16).then(
            (contract)=>{
                return contract.tzip16().getMetadata()
            }
        ).then((metadata)=>{
            console.log(metadata)
        });
    }

    async function methods(){
        Tezos.contract.at('KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5').then((contract)=>{
        console.log(contract).parameterSchema.ExtractSignatures()    
        });
    }

    async function storage(){
        Tezos.wallet.at('KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5').then((c)=>{
            c.storage().then((res)=>{
                console.log(res);
            });
        });;
    }

    async function balanceof(){

        const useraddress = wallet.pkh || (await wallet.getPKH());
        setAddress(useraddress);
        //test nft address
        const address = 'tz1UCn9JD1ZdiP6npnBPRLABbwRHNu4fkT9U'
        const params = []
        let response=null
        for(let i=0;i<83;i++){
            params.push({owner:address,token_id:i.toString()})
        }
        Tezos.wallet.at('KT1A1V3Btys7VyHFdn3nVoqMJdtbASNq74m5').then((c)=>{
            return c.views.balance_of(
                params
            ).read();
        }).then((res)=>{
            // console.log(JSON.stringify(res,null,2))
            console.log("owns nft "+checkOwnership(res))
        });
    }

    function checkOwnership(dict){
        let found=false
        var nftarr = []
        dict.map((item)=>{
            if(item['balance'].toString()=='1'){
                found=true;
                nftarr.push(item['request']['token_id'].toString()) 
                console.log(JSON.stringify(item))
            }
        });
        if(found){
            setOwnsNFT(true)
        }
        else{
            setOwnsNFT(false)
        }
        console.log(nftarr)
        if(nftarr.length!=0){
        setNFTs(nftarr)
        }
        else{
          setNFTs(null)
        }
        setLoading(false)
        return found;
    }

    async function connectWallet(){
        
        await wallet.client.requestPermissions({
            network: {
              type: 'mainnet',
            },
          });
        Tezos.setWalletProvider(wallet);
        connectionStatus();
    }

    function logOut(){
        console.log("Logout");
        if(wallet){
            wallet.disconnect()
            wallet.client.clearActiveAccount()
            connectionStatus();
        }
    }
    if(loading===true){
        return(<h1 className='text centerInScreen'>Loading...</h1>);
    }
    else{
        return(
        <div>
            {!connected?
            <button className='button centerInScreen' onClick={connectWallet}>Connect Wallet</button>:
            <div className='centerInScreen div'>
                <button className='button centerInScreen' style={{top:"20px"}} onClick={logOut}>Disconnect</button>
                {ownsNFT?
                // <h1 className='text centerInScreen'>You own MIU NFT {nfts}</h1>:
                <Data address={address} nfts={nfts}/>:
                <h1 className='text centerInScreen'>You don't own any MIU NFT on this account!</h1>
                }
            </div>
            }
            </div>
    );
}

}
export default Component;