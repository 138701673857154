import logo from './logo.svg';
import './App.css';
import Component from './components/Component';
import Redirect from './components/Redirect'
import {Routes,Route} from 'react-router-dom'
function App() {
  return (
    <Routes>
      <Route index element={<Component/>}/>
      <Route path='/redirect' element={<Redirect/>}/>
    </Routes>
  );
}

export default App;
