import { useEffect,useState } from "react";

const Redirect = () =>{

    const[code,setCode] = useState()

     useEffect(()=>{
        getCode()
     },[]);


     function getCode(){
        var url = new URL(window.location.href)
        var authcode = url.searchParams.get('code')
        console.log(authcode)
        setCode(authcode)
        window.opener.postMessage("code "+authcode)
        window.close()
     }

    

    return(
        <></>
    );
}

export default Redirect;